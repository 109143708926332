import { isVisible } from '../utils/elementProperties';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';

let activeOverlay;
let activeOverlayInner;
let activeOverlayCloseBtn;

const openClass = 'employee-list__overlay--open';

function outsideClickListener(e) {
    if (!activeOverlayInner.contains(e.target) && isVisible(activeOverlayInner)) {
        // or use: event.target.closest(selector) === null
        overlayClose(e);
        activeOverlay.removeEventListener('click', outsideClickListener);
    }
}

function overlayOpen(e) {
    const employeeItem = e.currentTarget.closest('.employee-list__employee');
    const target = e.target;
    activeOverlay = employeeItem.closest('.employee-list').querySelector('.employee-list__overlay');

    if (!target.classList.contains('employee-list__employee-link')) {
        typeof e.preventDefault == 'function' ? e.preventDefault() : null;

        if (activeOverlay) {
            activeOverlay.classList.add(openClass);
            activeOverlayInner = activeOverlay.querySelector('.employee-list__overlay-inner');
            activeOverlayCloseBtn = activeOverlay.querySelector('.employee-list__overlay-close');

            if (activeOverlayCloseBtn) {
                activeOverlayCloseBtn.addEventListener('click', overlayClose);
            }

            activeOverlay.addEventListener('click', outsideClickListener);

            // append content to overlay
            const overlayContent = activeOverlay.querySelector('.employee-list__overlay-content');
            if (overlayContent) {
                const employeeItemCloned = employeeItem.cloneNode(true);

                overlayContent.innerHTML = '';
                overlayContent.appendChild(employeeItemCloned);
            }
        }
    }
}

function overlayClose(e) {
    e.preventDefault();
    disableScrollLock();
    activeOverlay.classList.remove(openClass);

    if (activeOverlayCloseBtn) {
        activeOverlayCloseBtn.removeEventListener('click', overlayClose);
    }
}

export function setupEmployeeList(selector = '.employee-list') {
    const employeeLists = document.querySelectorAll(selector);

    Array.from(employeeLists).forEach(employeeList => {
        const employeeReadMoreLinks = employeeList.querySelectorAll('.employee-list__list .employee-list__employee-read-more-link');

        Array.from(employeeReadMoreLinks).forEach(employeeReadMoreLink => {
            employeeReadMoreLink.addEventListener('click', overlayOpen);
        });
    });
}
