function filterClick(filter, filterValue, filters, filterItems, moduleId) {
    const visibleFilterItems = [];
    const cleanFilterValue = filterValue.trim().toLowerCase();

    // toggle aria-selected for filters
    for (let i = 0; i < filters.length; i++) {
        const filterItem = filters[i];

        if (filterItem == filter) {
            filterItem.setAttribute('aria-selected', true);
        } else {
            filterItem.setAttribute('aria-selected', false);
        }
    }

    // show/hide filter items
    for (let i = 0; i < filterItems.length; i++) {
        const item = filterItems[i];
        let itemFilterValues = item.getAttribute('data-filter-value').split(',');
        itemFilterValues = itemFilterValues.map(value => value.trim().toLowerCase());

        if (filterValue !== '*' && itemFilterValues.indexOf(cleanFilterValue) === -1) {
            item.setAttribute('aria-hidden', true);
        } else {
            item.setAttribute('aria-hidden', false);
            visibleFilterItems.push(item);
        }

        // remove even/odd classes
        item.classList.remove('even');
        item.classList.remove('odd');
    }

    // add even/odd classes
    for (let i = 0; i < visibleFilterItems.length; i++) {
        const item = visibleFilterItems[i];

        if (i % 2 == 0) {
            item.classList.add('even');
        } else {
            item.classList.add('odd');
        }
    }
}

export function setupFilters(selector = '[data-action*="filter"]') {
    const filterContainers = document.body.querySelectorAll(selector);

    Array.from(filterContainers).forEach(filterContainer => {
        const filters = filterContainer.querySelectorAll('[data-filter]');
        const targetContainer = document.querySelector(filterContainer.getAttribute('data-target-container'));
        const module = filterContainer.closest('.module');
        const moduleId = module ? `#${module.id}` : '';

        if (targetContainer) {
            const filterItems = targetContainer.querySelectorAll('[data-filter-value]');

            Array.from(filters).forEach(filter => {
                const filterValue = filter.getAttribute('data-filter');

                // filter by click
                filter.addEventListener('click', () => {
                    filterClick(filter, filterValue, filters, filterItems, moduleId);
                });
            });
        }
    });
}
