import Rellax from 'rellax';
import { onWindowResize } from '../utils/windowResize';

export function setupRellax(xSelector = '[data-rellax-vertical-scroll-axis=x]', ySelector = '[data-rellax-vertical-scroll-axis=y]') {

    // Rellax on X axes
    const rellaxXElements = document.querySelectorAll(xSelector);
    if (rellaxXElements.length > 0) {
        const rellaxX = new Rellax(xSelector, {
            horizontal: true
        });

        onWindowResize(() => {
            rellaxX.refresh();
        });
    }

    // Rellax on Y axes
    const rellaxYElements = document.querySelectorAll(ySelector);
    if (rellaxYElements.length > 0) {
        const rellaxY = new Rellax(ySelector);

        onWindowResize(() => {
            rellaxY.refresh();
        });
    }
}