
/**
 * Simply adds a class to target field if it has a value and removes it if not.
 *
 * @param {Object || HTMLElement} e - Event or field element
 */
 function checkFieldValue(e) {
    const field = e.currentTarget || e;
    const fieldWrap = field.closest('.form__field-wrap');

    if (fieldWrap) {
        field.value ? fieldWrap.classList.add('js-has-value') : fieldWrap.classList.remove('js-has-value');
    }
}

/**
 * Sets up 'change'-eventhandler as checkValidation on given fields
 *
 * @param {HTMLElement} form - Form container with fields to validate
 */

export function watchFormFields(selector = '[data-action*="form-watch-fields"]') {
    const form = document.body.querySelectorAll(selector);

    if (form) {
        for (let f = 0; f < form.length; f++) {
            const fields = form[f].querySelectorAll('input, select, textarea');

            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];

                //Check for prefilled fields
                checkFieldValue(field);
                if (field.type === 'select-one' || field.type === 'select-multiple') {
                    field.addEventListener('change', checkFieldValue);
                } else {
                    field.addEventListener('keyup', checkFieldValue);
                }
            }
        }
    }
}