import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';
import { breakpoints, currentWindowWidth, onWindowResize } from '../utils/windowResize';

export let nav;
export let languageSelector;

export function setupNavigation(selector = '.navigation', sticky = true) {
    nav = document.body.querySelector(selector);

    if (nav) {
        const navBurger = nav.querySelector('.navigation__burger');
        const subLinks = nav.querySelectorAll('.navigation__item--sub > .navigation__link');

        if (navBurger) {
            navBurger.addEventListener('click', toggleMenuOpen);
        }

        if (sticky) {
            stickyNavOnScroll(nav, 'navigation--sticky', 30, 'navigation--going-up');
        }

        Array.from(subLinks).forEach(subLink => {
            subLink.addEventListener('click', e => {

                if (currentWindowWidth < (breakpoints['lg'] - .02)) {
                    e.preventDefault();

                    const navItem = e.target.parentNode;
                    if (navItem) {
                        const subNavElm = navItem.querySelector('.navigation__sub-navigation');
                        if (subNavElm) {
                            if (!navItem.classList.contains('navigation__item--sub-closed')) {
                                closeSubNavigation(navItem, subLink, subNavElm);
                            } else {
                                openSubNavigation(navItem, subLink, subNavElm);
                            }
                        }
                    }
                }
            });
        });

        setupLanguageSelector();
        onWindowResize(setupLanguageSelector);
    }
}

function setupLanguageSelector () {
    const languageSelectorActiveItem = nav.querySelector('.navigation__language-selector-item--active');
    languageSelector = nav.querySelector('.navigation__language-selector');

    if (languageSelectorActiveItem && languageSelector) {
        languageSelectorActiveItem.addEventListener('click', toggleLanguageSelector);
    }
}

function toggleMenuOpen() {
    if (nav.classList.contains('navigation--open')) {
        disableScrollLock();
        nav.classList.remove('navigation--open');

        if (window.pageYOffset > 50) {
            setTimeout(() => nav.classList.add('navigation--going-up'), 40);
        }

    } else {
        enableScrollLock();
        nav.classList.add('navigation--open');
    }
}

function openSubNavigation(navItem, link, subNavElm) {
    subNavElm.style.height = `${subNavElm.scrollHeight}px`;
    subNavElm.addEventListener('transitionend', function(e) {
        if (e.propertyName === 'height') {
            subNavElm.style.height = null;
        }
    });

    navItem.classList.remove('navigation__item--sub-closed');
    // subNavElm.setAttribute('aria-hidden', false);
    // link.setAttribute('aria-expanded', true);
}

function closeSubNavigation(navItem, link, subNavElm) {
    subNavElm.style.height = `${subNavElm.scrollHeight}px`;
    setTimeout(() => {
        subNavElm.style.height = '0';
    }, 0);

    navItem.classList.add('navigation__item--sub-closed');
    // subNavElm.setAttribute('aria-hidden', true);
    // link.setAttribute('aria-expanded', false);
}

function toggleLanguageSelector(e) {
    e.preventDefault();

    if (currentWindowWidth > breakpoints['lg']) {
        if (languageSelector.classList.contains('navigation__language-selector--closed')) {
            languageSelector.style.height = `${languageSelector.scrollHeight}px`;
            languageSelector.addEventListener('transitionend', function(e) {
                if (e.propertyName === 'height') {
                    languageSelector.style.height = null;
                }
            });

            languageSelector.classList.remove('navigation__language-selector--closed');
        }
        else {
            languageSelector.style.height = `${languageSelector.scrollHeight}px`;
            setTimeout(() => {
                languageSelector.style.height = '100%';
            }, 0);

            languageSelector.classList.add('navigation__language-selector--closed');
        }
    }
}