function loadOsirisScript(callback) {
    const osirisScript = document.getElementById('osiris-script');

    // Check if the script has already been loaded
    if (!osirisScript) {
        const script = document.createElement('script');
        script.id = 'osiris-script';
        script.src = 'https://accunia.os.fundconnect.com/lib/osiris.umd.js';
        script.type = 'text/javascript';

        script.onload = function() {
            callback();
        };

        document.head.appendChild(script);
    }
    else {
        callback();
    }
}

function createOsirisInstance(osirisContainerId) {
    const osirisInstance = new window.osiris.FcnOsiris();

    osirisInstance.mount({
        solution: 'default',
        server: 'accunia',
        env: 'prod',
        pageName: 'InnerValue',
        containerId: osirisContainerId,
        params: {
            language: 'da-DK',
            currency: 'DKK'
        }
    });

    // Add osiris stylesheet
    const currentDate = new Date();
    const day = currentDate.getDate().toString()
        .padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours().toString()
        .padStart(2, '0');
    const minutes = currentDate.getMinutes().toString()
        .padStart(2, '0');

    const timestamp = `${day}${month}${year}${hours}${minutes}`;

    osirisInstance.addStylesheet(`/assets/css/osiris.min.css?v=${timestamp}`);
}

export function setupOsiris(selector = '[data-action*="osiris"]') {
    const osirisElements = document.querySelectorAll(selector);
    Array.from(osirisElements).forEach(osirisElm => {
        const osirisContainerId = osirisElm.getAttribute('data-osiris-container');
        if (osirisContainerId) {

            // Load osiris
            loadOsirisScript(() =>
            {
                let checkOsirisCount = 0;
                const checkOsiris = setInterval(() => {
                    checkOsirisCount++;

                    if (checkOsirisCount < 16) {
                        if (window.osiris) {
                            // Create the osiris instance
                            createOsirisInstance(osirisContainerId);

                            // Clear the interval
                            clearInterval(checkOsiris);
                        }
                    }
                    else {
                        clearInterval(checkOsiris);
                    }
                }, 100);
            });
        }
    });
}
