import { onReady } from './utils/onReady';
import { isTouch, initVhUnitOverwrite } from './utils/helpers';
import { setupNavigation } from './layout/navigation';
import { setupRellax } from './components/rellax';
import { setupVideoModules } from './modules/video';
import { setupEmployeeList } from './modules/employee-list';
import { setupFilters } from './components/filter';
import { watchFormFields } from './components/form';
import { setupOsiris } from './modules/osiris';

function init() {
    document.body.classList.remove('standby');

    // Detect touch
    if (isTouch) {
        document.body.classList.add('touch-device');
    }

    onReady(() => {
        initVhUnitOverwrite();
        setupNavigation();
        setupRellax();
        setupVideoModules();
        setupEmployeeList();
        setupFilters();
        watchFormFields();
        setupOsiris();
    });
}

init();